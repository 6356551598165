<template>
  <v-container class="theo-map">
    <!-- Story: {{ loop }} -->
    <!-- Marker: {{ markerIndex }} -->
    <l-map
    class="map"
    style=""
    :zoom="map.zoom"
    :options="map.options"
    ref="map"
    :center="map.center"
    >
    <l-tile-layer :options="map.options" :url="map.url"></l-tile-layer>
    <l-marker
    v-for="(place, idx) in activeMarkers"
    :key= "''+storyIndex+'-'+idx"
    :options="{ 'opacity': place.opacity}"
    :lat-lng="place.pos"
    >
    <l-popup
      :ref="'popup'"
      v-if="place.text"
      :options="{
        autoClose: false,
        keepInView: true,
        className: place.className ? place.className : '',
        offset: [0, 42],
        closeButton: false,
      }">{{ place.text }}</l-popup>
  </l-marker>
  
  <l-polygon :lat-lngs="irelandlatlng" 
  :color="'green'">
  ></l-polygon>
</l-map>
</v-container>
</template>

<script>
import { Icon } from 'leaflet';
import irelandlatlng from '@/data/ireland_latlng.js'
// import simplify from '@/assets/js/simplify.js'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
import H from '@/assets/js/flexiblepolyline.js'
import { LMap, LTileLayer, LMarker, LPopup, LPolygon } from 'vue2-leaflet';


var london = [51.50697567645422, -0.1269200607375354];
var ireland = [54.87233383185175, -8.057058257532901];
var paris = [48.87950390521022, 2.4017640837502747];
var grenoble = [45.20730127210854, 5.751258624376296];
var marseille = [43.30155608858722, 5.372835193296526];
var germany = [45.501452194668516, -19.901562845267582];
var nantes = [47.23297518501901, -1.590359354069673];
var bordeaux = [44.82823928387336, -0.6375276973553146];
var perpignan = [42.715756466770976, 2.9368442581897765];
var clermont = [45.859620900087975, 3.012820006811868];
var blackburn = [53.77299794669295, -2.442992346812176];
var kerry = [52.23680947496048, -10.171391656174196]
var slide1center = [53.96607258705106, -4.615132139003548];
var slide2center = [46.49950887298837, 2.7262928877092967];
// var wp = [
//   {"text": "here","pos": marseille, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": kerry, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": paris, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": grenoble, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": nantes, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": bordeaux, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": perpignan, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": clermont, className: 'small', opacity: "0.0"},
//   {"text": "here","pos": blackburn, className: 'small', opacity: "0.0"},
// ]
const initialStories = [
    {markers: [
      {"text": "👶🏼 Je suis né à Londres", "pos": london, "opacity": "1.0"},
      {"text": "☘️ de parents irlandais ","pos": ireland, "opacity": "0.0"}]},
      {markers: [
        {"text": "J'ai vécu à Paris", "pos": paris,"opacity": "0.0"},
        {"text": "Grenoble", "pos": grenoble,"opacity": "0.0"},
        {"text": "et Marseille","pos": marseille,"opacity": "0.0"}]}, 
      {
        markers: [
        {"text": "j'ai travaillé pour des clients", "pos": germany, opacity: "0.0", className: 'no-tip'},
        {"text": "ici","pos": marseille, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": kerry, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": paris, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": grenoble, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": nantes, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": bordeaux, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": perpignan, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": clermont, className: 'small', opacity: "0.0"},
        {"text": "ici","pos": blackburn, className: 'small', opacity: "0.0"},
      ]}, 
]
function initialState() {

  return {
    irelandlatlng: [[]],
    loop: null,
    timers: [],
    stories: initialStories,
        storyIndex: 0,
    markerIndex: 0,
        wpIndex: 0,
        colors: ['blue', 'light-blue', 'green', 'light-green', 'orange', 'deep-orange'],
        leafletMap: null,
        map: {
          url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYm9id2F0Y2hlcngiLCJhIjoiY2xiMGwwZThrMWg3aTNwcW1mOGRucHh6bSJ9.kNHlmRqkRSxYNeipcKkJhw   ',
          
          attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          zoom: 10,
          options: {
            zoomSnap: 0.5,
            zoomOffset: -1,
            tileSize: 512
          },
          center: london,
        }
      }
}
export default {
  name: 'TheoMap',
  components: {
    LMap,
    LTileLayer,
    // LGeoJson,
    LPolygon,
    LMarker,
    LPopup
  },
  methods: {
    geoStyle() {
      return { color: 'green'}
    },
    decode(str){
      let lines = H.decode(str);
      return {
        "type": "Feature",
        "geometry": {
          "type": "LineString",
          "coordinates": lines.polyline
        }
      };
    },
    
    deleteMe(idx) {
      this.colors.splice(idx, 1)
    },
    flip(latLngArray) {
      return latLngArray.map(function (it) {
        return [ it[1], it[0]];
      })
    },
    incrIndex() {
      let popup = this.$refs['popup'][this.markerIndex];
      // this.leafletMap.flyTo(story.markers[this.markerIndex].pos, 5, { duration: 2 })
      if(popup)
        popup.mapObject.toggle();
      this.markerIndex++;
    },
    playAnimation() {
      setInterval(this.incrIndex, 2000);
    },
    gotoireland() {
      
      this.irelandlatlng = this.flip(irelandlatlng);
      this.leafletMap.flyTo(slide1center, 4.8, { duration: 2 })
    },
    gotofrance() {
      this.markerIndex = 0;
      this.storyIndex++;
      this.irelandlatlng = [[]];
      this.leafletMap.flyTo(slide2center, 5.2, { duration: 2 })
    },
    gotoeurope() {
      this.markerIndex = 0;
      this.storyIndex++;
      this.irelandlatlng = [[]];
      this.leafletMap.flyTo(slide2center, 4, { duration: 2 })
    },
    addWorkplace() {
      if (this.markerIndex < this.stories[2].markers.length)
      {
        this.incrIndex();
      }
      else {
        console.log("CLEAR!")
        clearInterval(this.loop)
      }
    },
    workLoop() {
      this.markerIndex = 1;
      this.loop = setInterval(this.addWorkplace, 500)
    },
    sanitize() {
      Object.assign(this.$data, initialState());
      this.leafletMap = this.$refs['map'].mapObject;
      this.player();
    },
    player() {
      this.timers.push(setTimeout(this.incrIndex, 500));
      this.timers.push(setTimeout(this.gotoireland, 1000));
      this.timers.push(setTimeout(this.incrIndex, 3500));
      this.timers.push(setTimeout(this.gotofrance, 6500));
      this.timers.push(setTimeout(this.incrIndex, 7500));
      this.timers.push(setTimeout(this.incrIndex, 8500));
      this.timers.push(setTimeout(this.incrIndex, 9500));
      this.timers.push(setTimeout(this.gotoeurope, 12500));
      this.timers.push(setTimeout(this.incrIndex, 13500));
      this.timers.push(setTimeout(this.workLoop, 14500))
      this.timers.push(setTimeout(this.sanitize, 14500 + this.stories[2].markers.length * 500 + 1000));

    }
  },
  beforeDestroy() {
    for (var timer of this.timers) {
      console.log(timer)
      clearInterval(timer)
      clearInterval(this.loop)
    }
  },
mounted() {
  this.leafletMap = this.$refs['map'].mapObject;
  // this.playAnimation();
  this.player();
},
computed: {
  activeMarkers() {
    return this.stories[this.storyIndex].markers
  },
},
  data() {
    return initialState()
    }
  }
</script>

<style lang="scss">
.theo-map {
  height: 400px;
  width: 100%;
  position: relative;
  .leaflet-control-attribution {
    display: none;
  }
  .map {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
    width: 100%;
  }
  .leaflet-popup-content-wrapper {
    background: var(--domain-color);
  }
  .leaflet-popup.small {
    padding: 0;
    .leaflet-popup-content {
      width: 3em;
      margin: 0;
      text-align: center;
    }
    .leaflet-popup-content-wrapper .leaflet-popup-content {
      padding: 2px;
      font-size: 12px;
      animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
    transform: scale(0) rotate(0);
  }
  25% {
    transform: scale(0.2) rotate(-15deg);
  }
  75% {
    transform: scale(2.4) rotate(0);
  }
  to {
    transform: scale(1) rotate(15deg);
  }
}

  }
  .leaflet-popup-content-wrapper .leaflet-popup-content {
    color: white;
    font-size: 16px;
  }
  .no-tip {
    .leaflet-popup-tip {
      display: none;
    }
  }
  
  .leaflet-popup-tip {
    // display: none;
    background: var(--domain-color);
  }
}
</style>