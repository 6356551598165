<template>
    <v-navigation-drawer
    v-model="drawer"
    fixed
    :bottom="$vuetify.breakpoint.mdAndUp ? false : true"
    :right="$vuetify.breakpoint.mdAndUp"
    :permanent="$vuetify.breakpoint.mdAndUp && ($route.name != 'home')"
    class="mb-0 pb-0"
    >
    <v-list
        nav
        dense
        class="mb-0 pb-0"
    >
        <v-list-item-group
        v-model="group"
        class="d-flex flex-column"
        :class="$vuetify.breakpoint.mdAndUp ? 'flex-column' : 'flex-column'"
        active-class="primary--text text--accent-4"
        >
            <v-list-item class="d-flex align-bas" @click="drawer = !drawer">
              <!-- <router-link to="/">
                <span class="mini-logo"><v-icon color="primary" >mdi-play-circle</v-icon></span>
              </router-link> -->
              <v-spacer></v-spacer>
              <span class="mini-logo">th:
              </span>
            </v-list-item>
            <v-list-item class="d-flex justify-end">
                <v-list-item-group
                v-model="group"
                class="domain-group"
                active-class="primary--text text--accent-4"
                >
                <router-link 
                  class="nav-link d-flex justify-end"
                  to="/development">
                  
                  {{ $t('DEVELOPMENT') }}
                </router-link>
                <span v-if="showDomains">
                  <v-list-item 
                      v-for="(dom, idx) in domains"
                      :key="idx"
                      :to="'/development/'+dom.link"
                      :class="dom.link"
                      class="domain-item "
                  >
                      <v-list-item-title>
                        <TheoDomainIcon
                          :domain="dom"
                          :title="getTitle(dom)"
                          :label=true></TheoDomainIcon>
                      </v-list-item-title>
                  </v-list-item>
                </span>
                </v-list-item-group>
            </v-list-item>
            <v-list-item to="/past-projects" class="d-flex justify-end">
                {{ $t('PAST PROJECTS') }}
            </v-list-item>
            <v-list-item to="/contact" class="d-flex justify-end">
                {{ $t('CONTACT') }}
            </v-list-item>
        </v-list-item-group>
    </v-list>
    <template v-slot:append v-if="$vuetify.breakpoint.mdAndUp">
      <v-list
        nav
        dense
        class="ma-0 pa-0"
      >
          <v-list-item class="d-flex align-end pa-0 my-0 mx-4" v-if="false">
            <TheoLangPicker
              :socials="socials"
              :labels="false"
            ></TheoLangPicker>
          </v-list-item>
          <v-list-item class="d-flex  align-end py-0 pa-4 my-0">
            <TheoSocials
              :socials="socials"
              :labels="false"
              :desat="true"
            ></TheoSocials>
          </v-list-item>
          <v-list-item disabled class="justify-center align-end pb-0 mb-0">
            <small class="overline">
              &copy; 2024 Theo Hennessy
            </small>
          </v-list-item>
      </v-list>
    </template>
    <template v-slot:prepend v-if="!$vuetify.breakpoint.mdAndUp && false">
      <v-list
        nav
        dense
        class="ma-0 pa-0"
      >
          <v-list-item class="d-flex align-end pa-0 my-0 mx-4" v-if="false">
            <TheoLangPicker
              :socials="socials"
              :labels="false"
            ></TheoLangPicker>
          </v-list-item>
          <v-list-item class="d-flex  align-end py-0 pa-4 my-0">
            <TheoSocials
              :socials="socials"
              :labels="false"
              :desat="true"
            ></TheoSocials>
          </v-list-item>
          <v-list-item disabled class="justify-center align-end pb-0 mb-0">
            <small class="overline">
              &copy; 2024 Theo Hennessy
            </small>
          </v-list-item>
      </v-list>
    </template>
    </v-navigation-drawer>
  </template>
  
  <script>
import TheoDomainIcon from '@/components/TheoDomainIcon.vue';
import TheoLangPicker from '@/components/TheoLangPicker.vue';
import TheoSocials from '@/components/TheoSocials.vue';

  export default {
  
    name: 'TheoNav',
  
    data: () => ({
        group: null,
    }),
    computed: {
        drawer:{
            get() { return this.$store.state.drawer; },
            // get() { return true },
            set(val) { this.$store.commit('setDrawer', val); }
        },
        domains() { return this.$store.state.domains},
        socials() { return this.$store.state.socials},
        showDomains() { 
          return this.$route.path.indexOf('development') > -1;
        }
    },
    components: {
      TheoDomainIcon, TheoSocials, TheoLangPicker
    },
    methods: {
      getTitle(dom) {
        return this.$t('domains.'+dom.link+'.title')
      }
    },
    watch: {
      group() {
        this.drawer = false;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  a.nav-link {
    text-decoration: none;
     color: unset;
  }
  .mini-logo {
    position: relative;
    top: -2px;
    font-size: 1.2em;
    font-family: monospace;
    font-weight: bold;
    color: var(--v-primary-base);
  }
  .v-list-item {
    text-transform: uppercase;
    margin-left: 0;
    .mini-logo{
      text-transform: lowercase;
    }
  }
  .domain-group {
    .domain-item {
      margin-left: 0;
    }
    .v-list-item.domain-item {
      .v-list-item__title{
        font-size: 0.7rem !important;
      }
      text-transform: uppercase;
    }
  }
  .v-application .overline {
    font-weight: 400 !important;
    font-size: 0.7rem !important;
  }
  .rot90{
    display: inline-block;
    transform: rotate(-90deg);
  }
  </style>