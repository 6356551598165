import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import "leaflet/dist/leaflet.css";
import ProjectList from '@/data/ProjectList'
import DomainList from '@/data/DomainList'
import SocialList from '@/data/SocialList'

export default new Vuex.Store({
  state: {
    appName: "Theo Hennessy",
    drawer: false,
    playAnimations: true,
    shownDomainNav: false,
    projects: ProjectList,
    domains: DomainList,
    socials: SocialList,
  },
  getters: {
  },
  mutations: {
    setDrawer(state, val) {
      state.drawer = val;
    },
    setShownDomainNav(state, val) {
      state.shownDomainNav = val;
    },
    setPlayAnimations(state, val) {
      state.playAnimations = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
