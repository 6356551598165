
import TheoResponsive from '@/components/demos/TheoFrontDemo.vue'
import TheoMap from '@/components/demos/TheoMapDemo.vue'
// import TheoBackDemo from '@/components/demos/TheoBackDemo.vue'
import TheoBiDemo from '@/components/demos/TheoBiDemo.vue'


const DomainList = {
    "frontend": {
        primColor: "#42B580",
        link: "frontend",
        slug: "fr",
        displaySize: 12,
        code: "frontend",
        src: "vue-badge-outlined.svg",
        slides: [
          { type: 'img', src: '/renders/AHA_mockup.jpg' },
          { type: 'cpt', src: TheoResponsive},
          { type: 'img', src: '/renders/clicstreet_mockup.png' }
        ],
        clientLead: "J'ai déjà utilisé Vue.js pour concevoir et/ou intégrer : ",
        sidebars: [
          {
            icon: 'mdi-cellphone',
            title: '“Mobile first”',
            description: "Parce qu’aujourd’hui près de <strong>60%</strong> du trafic internet se déroule sur mobile, ma démarche se focalise sur l’expression des informations essentielles.",
          },
          {
            icon: 'mdi-television',
            title: '“TV-friendly”',
            description: "Avec un enrichissement progressif des contenus pour des écrans plus larges."
          },
        ],
      },
    "backend":  {
        primColor: "#0C4B33",
        link: "backend",
        slug: "bk",
        code: "backend",
        displaySize: 12,
        src: "django-python-logo.png",
        slides: [
          { type: 'img', src: '/renders/django_code_mockup.jpg' },
          // { type: 'cpt', src: TheoBackDemo},
          { type: 'img', src: '/renders/django_mockup.png' },
        ],
        clientLead: "J'ai déjà utilisé Django pour concevoir et/ou intégrer : ",
        sidebars: [
          {
            icon: 'mdi-server',
            title: '“Robust architecture”',
            description: 'Concevoir des systèmes backend performants et évolutifs pour des applications à grande échelle.',
          },
          {
            icon: 'mdi-monitor',
            title: '“Scalable solutions”',
            description: 'Créer des architectures flexibles adaptées aux besoins actuels et futurs.'
          }
        ],
      },
    "machine-learning": {
        link: "machine-learning",
        code: "ml",
        slug: "ai",
        displaySize: 12,
        primColor: "#D55B53",
        src: "ml.jpeg",
        slides: [
          { type: 'img', src: '/renders/cogepart_mockup.jpg' },
          // { type: 'cpt', src: TheoBackDemo},
          { type: 'img', src: '/renders/ml_frameworks.png' },
        ],
        sidebars: [
          {
            icon: "mdi-robot",
            title: "Optimisation des processus",
            description: "Des algorithmes d'IA pour automatiser et optimiser les tâches complexes, comme l’optimisation des trajets ou la gestion des ressources."
          },
          {
            icon: "mdi-chart-line",
            title: "Prédiction et analyse de données",
            description: "Des modèles prédictifs puissants pour anticiper les tendances et améliorer la prise de décision grâce aux données historiques."
          }
        ],          
        clientLead: "J'ai fait du Machine-Learning sur les projets suivants : ",
      },
    "cartography":  {
        link: "cartography",
        code: "cartography",
        slug: "ma",
        primColor: "#5178F2",
        displaySize: 12,
        src: "map.png",
        slides: [
          { type: 'img', src: '/renders/LeZero_mockup.jpg' },
          { type: 'cpt', src: TheoMap},
          { type: 'img', src: '/renders/clicstreet_mockup.png' }
        ],
        sidebars: [
          {
            icon: "mdi-map-search",
            title: "Analyse géospatiale",
            description: "Exploitez les données géographiques pour des analyses approfondies, de la gestion des ressources à l'optimisation des parcours."
          },
          {
            icon: "mdi-earth",
            title: "Cartographie dynamique",
            description: "Des cartes interactives et des visualisations en temps réel pour des décisions basées sur des données géospatiales actualisées."
          }
        ],
        clientLead: "J'ai déjà utilisé Leaflet.js pour concevoir et/ou intégrer : ",
      },
    "business-intelligence":  {
        primColor: "#06C2D3",
        slug: "bi",
        link: "business-intelligence",
        displaySize: 12,
        clientLead: "J'ai développé des solutions BI pour : ",
        code: "bi",
        src: "dash.jpeg",
        sidebars: [
          {
            icon: "mdi-chart-line",
            title: "Visualisation des KPIs",
            description: "Des tableaux de bord interactifs pour suivre vos indicateurs clés et faciliter les décisions stratégiques."
          },
          {
            icon: "mdi-database",
            title: "Intégration de données",
            description: "Optimisez vos processus décisionnels avec des solutions d’intégration de données fiables, unifiant plusieurs sources pour une analyse fluide."
          }
        ],
        slides: [
          { type: 'cpt', src: TheoBiDemo},
        ],
      },
  }

export default DomainList