<template>
<v-card
    flat
    tile
    >
    <v-window
        v-model="slideIndex"
        class="caroussel"
    >
        <v-window-item
        v-for="(slide, n) in slides"
        :key="`card-${n}`"
        >
        <v-card
            color="grey"
            @mouseenter="pause"
        >
            <v-row
            class="fill-height d-block"
            >
            <span v-if="slide.type == 'cpt'">
              <component :is="slide.src"></component>
            </span>
            <v-card v-else>
                <v-img class="caroussel-image" contain height="400"  :src="slide.src"></v-img>
            </v-card>
            </v-row>
        </v-card>
        </v-window-item>
    </v-window>

    <v-card-actions class="justify-space-between">
        <v-btn
        text
        @click="prev"
        >
        <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group
        v-model="slideIndex"
        class="text-center"
        mandatory
        >
        <v-item
            v-for="n in slides.length"
            :key="`btn-${n}`"
            v-slot="{ active, toggle }"
        >
            <v-btn
            :input-value="active"
            icon
            @click="toggle"
            >
            <span v-if="active">
              <div class="pie-wrapper style-2" :class="'progress-'+ progress">
                <span class="label"><span class="smaller">%</span></span>
                <div class="pie">
                  <div class="left-side half-circle"></div>
                  <div class="right-side half-circle"></div>
                </div>
                <div class="shadow"></div>
              </div>
              <v-icon v-if="playInterval" @click="pause">mdi-pause</v-icon>
              <v-icon v-else @click="play">mdi-play</v-icon>
            </span>
            <v-icon v-else>mdi-record</v-icon>
            </v-btn>
        </v-item>
        </v-item-group>
        <v-btn
        text
        @click="next"
        >
        <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
    </v-card-actions>
</v-card>
  </template>
  <script>
  
  
import {defineAsyncComponent} from "vue";
  export default {
    name: 'TheoCaroussel',
    props: {
      slides: {type: Array, default() { return []}}
    },
    data() {
      return {
        autoPlay: true,
        length: 3,
        slideIndex: 0,
        loading: 0,
        playInterval: null,
        playSpeed: 8 * 1000,
        progress: 0,
        progressInterval: null,
        progressSpeed: 8 * 10,
      }
    },
    computed: {
    },
    mounted() {
      if (this.autoPlay)
        this.play()
    },
    methods: {
        play() {
          var vm = this;
          this.playInterval = setInterval(this.next, this.playSpeed);
          this.progressInterval = setInterval(
            function() {
              vm.progress += 1
              if (vm.progress > 100)
                vm.progress=0;
            }, this.progressSpeed
          )
        },
        pause() {
          if (this.playInterval){
            clearInterval(this.playInterval)
            clearInterval(this.progressInterval)
            this.playInterval = null;
            this.progressInterval = null;
            this.progress=0;
          }
        }, 
        comp(src) {
          return defineAsyncComponent(() => import(src))
        },
        next () {
          this.loading = 0;
          this.slideIndex = this.slideIndex + 1 === this.length
            ? 0
            : this.slideIndex + 1
        },
        prev () {
          this.loading = 0;
          this.slideIndex = this.slideIndex - 1 < 0
            ? this.length - 1
            : this.slideIndex - 1
        },
      }
  }
  </script>
  <style lang="scss" scoped>
  .clients .v-item--active .v-expansion-panel-header{
      // transition: all 0.5s ease;
      color: var(--domain-color);
      font-size: 1em;
      font-weight: bold;
    }
    .frontend {
      height: 100%;
      background: white;
      h1 {
        color:  #fff;
        background: var(--domain-color);
        display: block;
        width: 100%;
      }
      h3 {
        margin-bottom: 2rem;
      }
      a {
        font-weight: bold;
        color: var(--domain-color);
      }
      .v-chip {
        color: black;
        background: rgba(255,255,255,0.1);
      }
      .caroussel {
        // height: 320px;
      }
      .domain-card {
        background: var(--domain-color);
  
      }
      .icoded {
        color: white;
        background: var(--domain-color);
      }
    }
    h2 {
      font-size: 1.6rem;
    }
    .circle {
      border-radius: 50%;
    }

    .caroussel-image {
      background: #D4D5D7;
    }


    @import url(https://fonts.googleapis.com/css?family=Lato:700);

// -- vars
$bg-color: #34495e;
$default-size: 3em;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      animation: transform 1s ease;
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        animation: transform 0.1s ease;
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);
  
  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

// -- selectors
*,
*:before,
*:after {
  box-sizing: border-box;
}
.set-size {
  font-size: 10em;
}

.charts-container {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  margin: 15px;
  position: absolute;
  top: -24px;
  left: -17px;
  
  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    left: $label-font-size-redo / 10;
    line-height: $label-font-size-redo * .70;
    position: absolute;
    right: $label-font-size-redo / 10;
    text-align: center;
    top: $label-font-size-redo / 10;

    .smaller {
      color: #bdc3c7;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }

  &.style-2 {
    .label {
      background: none;
      color: #7f8c8d;

      .smaller {
        color: #bdc3c7;
      }
    }
  }

  &.progress-0 {@include draw-progress(0, #3498db);}
  &.progress-1 {@include draw-progress(1, #3498db);}
  &.progress-2 {@include draw-progress(2, #3498db);}
  &.progress-3 {@include draw-progress(3, #3498db);}
  &.progress-4 {@include draw-progress(4, #3498db);}
  &.progress-5 {@include draw-progress(5, #3498db);}
  &.progress-6 {@include draw-progress(6, #3498db);}
  &.progress-7 {@include draw-progress(7, #3498db);}
  &.progress-8 {@include draw-progress(8, #3498db);}
  &.progress-9 {@include draw-progress(9, #3498db);}
  &.progress-10 {@include draw-progress(10, #3498db);}
  &.progress-11 {@include draw-progress(11, #3498db);}
  &.progress-12 {@include draw-progress(12, #3498db);}
  &.progress-13 {@include draw-progress(13, #3498db);}
  &.progress-14 {@include draw-progress(14, #3498db);}
  &.progress-15 {@include draw-progress(15, #3498db);}
  &.progress-16 {@include draw-progress(16, #3498db);}
  &.progress-17 {@include draw-progress(17, #3498db);}
  &.progress-18 {@include draw-progress(18, #3498db);}
  &.progress-19 {@include draw-progress(19, #3498db);}
  &.progress-20 {@include draw-progress(20, #3498db);}
  &.progress-21 {@include draw-progress(21, #3498db);}
  &.progress-22 {@include draw-progress(22, #3498db);}
  &.progress-23 {@include draw-progress(23, #3498db);}
  &.progress-24 {@include draw-progress(24, #3498db);}
  &.progress-25 {@include draw-progress(25, #3498db);}
  &.progress-26 {@include draw-progress(26, #3498db);}
  &.progress-27 {@include draw-progress(27, #3498db);}
  &.progress-28 {@include draw-progress(28, #3498db);}
  &.progress-29 {@include draw-progress(29, #3498db);}
  &.progress-30 {@include draw-progress(30, #3498db);}
  &.progress-31 {@include draw-progress(31, #3498db);}
  &.progress-32 {@include draw-progress(32, #3498db);}
  &.progress-33 {@include draw-progress(33, #3498db);}
  &.progress-34 {@include draw-progress(34, #3498db);}
  &.progress-35 {@include draw-progress(35, #3498db);}
  &.progress-36 {@include draw-progress(36, #3498db);}
  &.progress-37 {@include draw-progress(37, #3498db);}
  &.progress-38 {@include draw-progress(38, #3498db);}
  &.progress-39 {@include draw-progress(39, #3498db);}

  &.progress-40 {@include draw-progress(40, #3498db);}
  &.progress-41 {@include draw-progress(41, #3498db);}
  &.progress-42 {@include draw-progress(42, #3498db);}
  &.progress-43 {@include draw-progress(43, #3498db);}
  &.progress-44 {@include draw-progress(44, #3498db);}
  &.progress-45 {@include draw-progress(45, #3498db);}
  &.progress-46 {@include draw-progress(46, #3498db);}
  &.progress-47 {@include draw-progress(47, #3498db);}
  &.progress-48 {@include draw-progress(48, #3498db);}
  &.progress-49 {@include draw-progress(49, #3498db);}

  &.progress-50 {@include draw-progress(50, #3498db);}
  &.progress-51 {@include draw-progress(51, #3498db);}
  &.progress-52 {@include draw-progress(52, #3498db);}
  &.progress-53 {@include draw-progress(53, #3498db);}
  &.progress-54 {@include draw-progress(54, #3498db);}
  &.progress-55 {@include draw-progress(55, #3498db);}
  &.progress-56 {@include draw-progress(56, #3498db);}
  &.progress-57 {@include draw-progress(57, #3498db);}
  &.progress-58 {@include draw-progress(58, #3498db);}
  &.progress-59 {@include draw-progress(59, #3498db);}

  &.progress-60 {@include draw-progress(60, #3498db);}
  &.progress-61 {@include draw-progress(61, #3498db);}
  &.progress-62 {@include draw-progress(62, #3498db);}
  &.progress-63 {@include draw-progress(63, #3498db);}
  &.progress-64 {@include draw-progress(64, #3498db);}
  &.progress-65 {@include draw-progress(65, #3498db);}
  &.progress-66 {@include draw-progress(66, #3498db);}
  &.progress-67 {@include draw-progress(67, #3498db);}
  &.progress-68 {@include draw-progress(68, #3498db);}
  &.progress-69 {@include draw-progress(69, #3498db);}
  
  &.progress-70 {@include draw-progress(70, #3498db);}
  &.progress-71 {@include draw-progress(71, #3498db);}
  &.progress-72 {@include draw-progress(72, #3498db);}
  &.progress-73 {@include draw-progress(73, #3498db);}
  &.progress-74 {@include draw-progress(74, #3498db);}
  &.progress-75 {@include draw-progress(75, #3498db);}
  &.progress-76 {@include draw-progress(76, #3498db);}
  &.progress-77 {@include draw-progress(77, #3498db);}
  &.progress-78 {@include draw-progress(78, #3498db);}
  &.progress-79 {@include draw-progress(79, #3498db);}

  &.progress-80 {@include draw-progress(80, #3498db);}
  &.progress-81 {@include draw-progress(81, #3498db);}
  &.progress-82 {@include draw-progress(82, #3498db);}
  &.progress-83 {@include draw-progress(83, #3498db);}
  &.progress-84 {@include draw-progress(84, #3498db);}
  &.progress-85 {@include draw-progress(85, #3498db);}
  &.progress-86 {@include draw-progress(86, #3498db);}
  &.progress-87 {@include draw-progress(87, #3498db);}
  &.progress-88 {@include draw-progress(88, #3498db);}
  &.progress-89 {@include draw-progress(89, #3498db);}

  &.progress-90 {@include draw-progress(90, #3498db);}
  &.progress-91 {@include draw-progress(91, #3498db);}
  &.progress-92 {@include draw-progress(92, #3498db);}
  &.progress-93 {@include draw-progress(93, #3498db);}
  &.progress-94 {@include draw-progress(94, #3498db);}
  &.progress-95 {@include draw-progress(95, #3498db);}
  &.progress-96 {@include draw-progress(96, #3498db);}
  &.progress-98 {@include draw-progress(98, #3498db);}
  &.progress-99 {@include draw-progress(99, #3498db);}
  &.progress-97 {@include draw-progress(97, #3498db);}
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }
  
  .label {
    background: transparent;
  }
  
  &.progress-65 {
    @include draw-progress--solid(65, #e67e22, $bg-color);
  }
  
  &.progress-25 {
    @include draw-progress--solid(25, #9b59b6, $bg-color);
  }
  
  &.progress-88 {
    @include draw-progress--solid(88, #3498db, $bg-color);
  }
}

  </style>
  