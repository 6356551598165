<template>
  <TheoProjectCard
    :extra="true"
    :project="project"
  ></TheoProjectCard>
</template>

<script>
import TheoProjectCard from './TheoProjectCard.vue';


  export default {
    name: 'TheoProjectDetail',
    data: function() {
      return {
      }
    },  
  props: ['project'],
  components: {TheoProjectCard},
  methods: {
    }
  }
</script>

<style lang="scss" scoped>
.proj-popup {
  background: white;
}
</style>