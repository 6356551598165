<template>
  <v-container>
    <span class="logo prevent-select">
      <span class="logo-word">
        <span class="word-initial">th:</span>
        <!-- <span class="word-rest">h</span>
        <span class="colon">:</span> -->
      </span>
    </span>
  </v-container>
</template>

<script>
  export default {
    name: 'TheoLogo',
    methods: {
      home() {
        this.$router.push('/');
      }
    }
  }
</script>

<style lang="scss" scoped>

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
.logo {
  cursor: pointer;
  font-family: monospace;
  font-size: 2rem;
  .logo-word {
    // text-transform: uppercase;
    font-weight: 200;
  }
}
</style>