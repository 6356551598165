<template>
  <v-container :style="cssVars" class="domain-page">
    <v-dialog
      v-model="projDetail"
      max-width="932"
    >
      <TheoProjectDetail
        :project="selectedProject">
      </TheoProjectDetail>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="3" class="d-none d-md-flex d-align-start">
        <TheoSidebar
          :domain="domain"
          :sidebars="[]"
        ></TheoSidebar>
      </v-col>
      <v-col cols="12" md="7" class="page mt-0">
    <v-alert
      dense
      type="info"
      v-if="domainMenuTooltip"
    >
    <v-icon>mdi-left</v-icon>{{ $t('you can switch domains here')}}
    </v-alert>
      <h1 class="">
        <div class="monospace primary--text">theo hennessy: </div>
      </h1>
      <v-menu offset-y left ref="domain-menu" v-model="domainMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="transparent"
            elevation="0"
          >
          <TheoDomainIcon
            :domain="domain"
            :title="copy.shortTitle"
            label="true"
          ></TheoDomainIcon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(dmn, index) in domains"
            :key="index"
            :to="'/development/'+dmn.link"
          >
            <v-list-item-title><TheoDomainIcon
            :domain="dmn"
            :title="dmn.link"
            label="true"
          ></TheoDomainIcon></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        <theo-caroussel
          :slides="domain.slides"
        ></theo-caroussel>
        <h3 class="domain-copy domain-lead">
          <i18n :path="copy.lead.text" tag="p"  v-if="copy.lead">
            <template v-slot:url>
              <a href="https://www.vuejs.org" class="domain-link" target="_blank">{{ $t(i18npath + '.lead.link') }}</a>
            </template>
          </i18n>
        </h3>
        <v-row>
          <v-col cols="12" md="6" class="domain-copy">
            <i18n :path="copy.para1.text" tag="p" v-if="copy.para1">
              <template v-slot:url1>
                <span class="strong-domain-text">{{ $t(i18npath + '.para1.link1') }}</span>
              </template>
              <template v-slot:url2>
                <span class="strong-domain-text">{{ $t(i18npath + '.para1.link2') }}</span>
              </template>
            </i18n>
            <p class="quote overline">
              {{  $t(i18npath+".quote") }}
            </p>
            <i18n :path="copy.para2.text" tag="p" v-if="copy.para2">
              <template v-slot:strong1>
                <strong>{{ $t(i18npath + '.para2.strongText') }}</strong>
              </template>
              <template v-slot:url1>
                <span class="strong-domain-text">{{ $t(i18npath + '.para2.link1') }}</span>
              </template>
              <template v-slot:url2>
                <span class="strong-domain-text">{{ $t(i18npath + '.para2.link2') }}</span>
              </template>
            </i18n>
          </v-col>
          <v-col cols="12" md="3" class="d-flex d-md-none">
            <TheoSidebar
              :domain="domain"
              :sidebars="[]"
            ></TheoSidebar>
          </v-col>
          <v-col cols="12" md="6">
            <TheoProjectList
              :projects="projects"
              :lead="domain.clientLead"
              @proj-clicked="showProj"
            ></TheoProjectList>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import TheoCaroussel from '@/components/TheoCaroussel.vue';
import TheoProjectList from '@/components/projects/TheoProjectList.vue';
import TheoDomainIcon from '@/components/TheoDomainIcon.vue';
import TheoSidebar from '@/components/TheoSidebar.vue';
import TheoProjectDetail from '@/components/projects/TheoProjectDetail.vue';

export default {
  name: 'DomainView',
  metaInfo() {
    return {
      title:
        this.$store.state.appName +
        ' - ' +
        this.$t('Development') +
        ' ' + 
        this.$t(this.domain.link)
    }
  }, 
  data() {
    return {
      i18npath: null,
      projDetail: false,
      selectedProject: null,
      copy: {},
      domainMenu: false,
      domainMenuTooltip: false,
      
    }
  },
  components: {TheoCaroussel, TheoProjectList, TheoSidebar, TheoDomainIcon, TheoProjectDetail},
  mounted() {
    this.buildCopy();
    this.$scrollTo({ el: '.v-container' });
  },
  created() {
    // if (!this.$store.state.shownDomainNav) {
    //   var vm = this;
    //   setTimeout(function () { 
    //     vm.domainMenu = true;
    //     vm.domainMenuTooltip = true 
    //   }, 1000);
    //   setTimeout(function () {  
    //     vm.domainMenuTooltip = false;
    //     vm.domainMenu = false;
    //     vm.$store.commit('setShowDomainNav', true) 
    //   }, 5000);
    // }
    // this.playInterval = setInterval(this.next, this.playSpeed);
  },
  computed: {
    domain() {
      return this.$store.state.domains[this.$route.name];
    },
    domains() {
      return this.$store.state.domains;
    },
    projects() {
      const domainName = this.$route.name
      return this.$store.state.projects.filter(function(cli){
        if (cli.domains.indexOf(domainName) > -1)
          return cli
      });
    },
    cssVars() {
      return {
        "--domain-color": this.$vuetify.theme.themes['light'][this.domain.code + '_color']
      }
    }
  },
  methods: {
    showProj(proj) {
      console.log(proj)
      this.selectedProject = proj;
      this.projDetail = true;
    },
    buildCopy() {
      this.i18npath = 'domains.'  + this.$route.name;
      this.copy = {
        shortTitle: this.$t(this.i18npath + '.title'),
        title: this.$t(this.i18npath + '.subtitle'),
        lead: {
          link: this.i18npath + '.lead.link',
          text: this.i18npath + '.lead.text'
        },
        para1: {
          link2: this.i18npath + '.para1.link2',
          link1: this.i18npath + '.para1.link1',
          text: this.i18npath + '.para1.text'
        },
        para2: {
          link2: this.i18npath + '.para2.link2',
          link1: this.i18npath + '.para2.link1',
          text: this.i18npath + '.para2.text'
        }
      }
    }
  },
  watch: {
    domain() {
      this.buildCopy();
    }
  }
}
</script>
<style lang="scss">
  .clients .v-item--active .v-expansion-panel-header{
    // transition: all 0.5s ease;
    color: var(--domain-color);
    font-size: 1em;
    font-weight: bold;
  }
  .domain-page {
    height: 100%;
    > .row{
      margin-bottom: 4em;
    }
    .strong-domain-text {
      font-weight: bold;
      color: var(--domain-color);
    }
    .monospace {
      font-family: monospace;
    }
    background: white;
    h1 {
      color:  #fff;
      display: block;
      width: 100%;
      font-size: 1.6em;
    }
    h3 {
      margin-bottom: 2rem;
    }
    .domain-lead {
      color: var(--domain-color);
    }
    a.domain-link {
      text-decoration: none;
      font-weight: bold;
      color: var(--domain-color);
    }
    .v-chip {
      color: black;
      background: rgba(255,255,255,0.1);
    }
    .domain-card {
      background: var(--domain-color);

    }
    .quote {
      color: var(--domain-color);
      text-transform: uppercase;
      font-size: 1.6rem !important;
      // text-align: center;
      font-weight: 300;
      // font-style: italic;
      
    }
    .domain-copy p:not(.quote) {
      text-indent: 4em;
      text-align: justify;
    }
  }
</style>
