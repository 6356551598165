<template>
  <v-container class="project-page" fluid>
    <v-dialog
      v-model="projDetail"
      max-width="932"
    >
      <TheoProjectDetail
        :project="selectedProject">
      </TheoProjectDetail>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="8" lg="10" class="page mt-0 project-column">
        <h1 class="header">
              <span class="monospace primary--text">theo hennessy: </span>
              <span class="overline prop">
                {{ $t('Past Projects') }}
              </span>
          </h1>
        <v-row>
          <v-col cols="12" md="4" v-for="(proj, idx) in filteredProj" :key="idx" >
            <TheoProjectCard
              class="project-card"
              :project="proj"
              @click.native="showProj(proj)"
            >
            </TheoProjectCard>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" fixed>
        <v-container class="d-none">
        {{ $t('Filters') }}
        <v-text-field
          name="name"
          :label="$t('search')"
          id="id"
        ></v-text-field>
        <span v-for="(domain, idx) in domains" :key="idx" class="d-flex">
          <TheoDomainIcon
            :domain="domain"
            label="true"
          ></TheoDomainIcon>
          <v-checkbox
            hide-details
            v-model="domainsActive[idx]"
            :label="`${domain.link }`"
            class="pb-5"
          ></v-checkbox>
        </span>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import TheoDomainIcon from '@/components/TheoDomainIcon.vue';
import TheoProjectCard from '@/components/projects/TheoProjectCard.vue';
import TheoProjectDetail from '@/components/projects/TheoProjectDetail.vue';
export default {
  name: 'ProjectView',
  metaInfo() {
    return {
      title:
        this.$store.state.appName +
        ' - ' +
        this.$t('Past Projects')
    }
  }, 
  data() {
    return {
      domainsActive: [],
      selectedProject: null,
      projDetail: false,
    }
  },
  mounted() {
    // this.showProj(this.projects[5]);
  },
  methods: {
    showProj(proj) {
      this.selectedProject = proj;
      this.projDetail = true;
    }
  },
  components: {
    TheoDomainIcon, TheoProjectDetail, TheoProjectCard
  },
  computed: {
    projects() {
      return this.$store.state.projects
    },
    domains() {
      return this.$store.state.domains
    },
    filteredProj() {
      return this.projects;
    }
  },
}
</script>
<style lang="scss" scoped>
h1.header {
  display: block;
  text-align: center;
  margin-bottom: 2em;
  .monospace {
    font-family: monospace;
  }
  width: 100%;
  font-size: 1.6em;
}
  .v-overlay__scrim {
    transition: all 0.5s ease-in-out;
  }
  .v-overlay--active .v-overlay__scrim {
    opacity: 1 !important;
    background-color: rgba(0,0,0,0.5) !important;
    backdrop-filter: blur(5px);
  }
  .project-page {
  // font-size: 0.6em !important;
  .project-column {
    padding-bottom: 8em;
    .proj-card {
      border: 1px solid #eee;
      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }
  }
  .project-title {
    font-size: 12px;
  }
}
</style>
