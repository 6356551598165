<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" lg="10">

        <v-row>
          <v-col cols="12" md="6" class="d-flex align-center justify-center">
    
            <h1 class="">
              <div class="monospace primary--text">theo hennessy</div>
              <div class="overline prop">
                {{  $t('vous propose: ') }}
              </div></h1>
          </v-col>
          <v-col cols="12" md="6">
            <theo-skills
              :textArray="$t('theo_builds')"
            ></theo-skills>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheoSkills from '@/components/TheoSkills.vue';

  export default {
    name: 'AboutView',
    metaInfo() {
        return { title: this.$store.state.appName }
    },  
    data: function() {
      return {}
    },  
    components: {
      TheoSkills,
    },
  }
</script>
<style lang="scss" scoped>
.d-block {
  display: block;
  width: 100%;
  text-align: center;
}
.monospace {
  font-family: monospace;
}
.overline.prop {
  position: relative;
  // left: 40px;
  top: -12px;
  font-size: 24px !important;
}
h1 > div {
  display: block;
  text-align: center;
}
</style>