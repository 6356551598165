<template>
  <v-container class="side-bar" :class="'domain-'+ domain.link">
    <v-row>
      <v-col cols="6" md="6" offset-md="3" class="d-flex align-start justify-center">
        <div class="image-wrapper">
          <v-img :src="'/' + domain.src" height="164" width="164" contain class="mb-6 mt-0 rounded-circle domain-logo" />
        </div>

      </v-col>
      <v-col cols="6" md="8" offset-md="2" >

        <v-card dark class="domain-card pa-4 rounded-0">
          <!-- Dynamic title and description -->
           <span v-for="(bar, idx) in domain.sidebars" :key="idx">
             <h4 class="text-center">
               <v-icon x-large>{{ bar.icon }}</v-icon><br/>{{ bar.title }}
             </h4>
             <div class="text-justify mb-4 d-none d-md-block" v-html="bar.description"></div>
           </span>
    
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
    name: 'TheoSidebar',
    props: {
      domain: { type: Object, default() {
        return {
          src: {},
          sidebars: [],
        };
      }},
    },
}
</script>


<style lang="scss" scoped>
  .clients .v-item--active .v-expansion-panel-header{
    // transition: all 0.5s ease;
    color: var(--domain-color);
    font-size: 1em;
    font-weight: bold;
  }
  .side-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: white;
    .domain-logo {
      border: 4px solid var(--domain-color);
      margin: 6em auto;
    }
    h1 {
      color:  #fff;
      background: var(--domain-color);
      display: block;
      width: 100%;
    }
    h3 {
      margin-bottom: 2rem;
    }
    a {
      font-weight: bold;
      color: var(--domain-color);
    }
    .v-chip {
      color: black;
      background: rgba(255,255,255,0.1);
    }
    .domain-card {
      background: var(--domain-color);

    }
    .icoded {
      color: white;
      background: var(--domain-color);
    }
  }
.domain-backend.side-bar, .domain-frontend.side-bar  {
  .domain-logo {
      border-width: 0;
  }
}
</style>
