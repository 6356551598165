<template>
    <v-container 
      class="d-flex justify-space-around socials pa-0"
      :class="{'desat-socials': desat}"
    >
      <a :href="social.url"
        target="_blank"
        v-for="(social, i) in activeSocials" :key="i"
        class="d-flex flex-column align-center justify-space-between social-link"
      >
        <v-img height=32 width=32 contain :src="social.icon"></v-img>
        <h3 v-if="labels" :style="colorMe(social.color)" class="overline">{{ social.name }}</h3>
      </a>
  </v-container>
</template>

<script>
  export default {
    name: 'TheoSocials',
    props: {
      socials: [],
      labels: {},
      desat: { type: Boolean, default: false }
    },
    methods: {
      colorMe(color) {
        return {"color": color}
      }
    },
    computed: {
      activeSocials() {
          return this.socials.filter(function(soc){ return soc.active});
      }
    }
  }
</script>

<style lang="scss" scoped>
.socials {
  a { text-decoration: none;}
  &.desat-socials {
    .social-link {
      transition: opacity 0.2s ease;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

}
</style>