<template>
    <v-container class="contact-page d-flex">
        <v-row class="justify-center align-center">
            <v-col cols="12" md="4">
                <v-img height="128" width="128" contain class="theo-photo rounded-pill" src="theo.jpg"></v-img>
                <h1>{{  $t('Contact') }}</h1>
                <p class="overline">{{ $t('contact.lead') }}</p>
                <v-form 
                    v-model="valid"
                    ref="form"
                    @submit.prevent="postMessage"
                >
                    <v-row no-gutters>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                            v-model="firstname"
                            :rules="nameRules"
                            :label="$t('First name')"
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                            v-model="lastname"
                            :rules="nameRules"
                            :label="$t('Last name')"
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        >
                            <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            :label="$t('E-mail')"
                            required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        >
                            <v-textarea
                            v-model="message"
                            :label="$t('Your message')"
                            auto-grow
                            :rules="messageRules"
                            outlined
                            rows="12"
                            row-height="15"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-btn
                                class="mr-4"
                                block
                                elevation="0"
                                type="submit"
                                color="primary"
                                :disabled="!valid"
                            >
                                {{ $t('Envoyer') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn
                                block  
                                color="error"
                                text
                                outlined
                                @click="clear"
                            >
                                {{ $t('Effacer') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-col cols="12" md="3" offset-md="1" class="">
                <v-img class="mx-auto" src="/qr-code.png" width="240"></v-img>
                <p class="overline text-center">{{ $t('contact.follow') }}</p>
                <TheoSocials
                :labels="true"
                :socials="socials"
                >
                </TheoSocials>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TheoSocials from '@/components/TheoSocials.vue';
import axios from 'axios';

export default {
    name: 'ContactView',
    metaInfo() {
        return { title: this.$store.state.appName + ' - ' + this.$t('Contact') }
    },  
    data: () => ({
        valid: false,
        form: null,
        firstname: '',
        lastname: '',
        nameRules: [],
        email: '',
        emailRules: [],
        message: '',
        messageRules: [],
    }),
    components: {
        TheoSocials
    },
    computed: {
        socials: {
            get() { return this.$store.state.socials}
        }
    },
    methods: {
        postMessage() {
            axios.post('https://www.theohennessy.com/api/contact/submit/', {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                message: this.message,
            })
            .then(response => {
                console.log(response.data.message);
            })
            .catch(error => {
                console.error(error.response.data);
            });
        },
        clear() {
            this.form.reset();
        }
    },
    mounted() {
        this.form = this.$refs['form']
        this.nameRules = [
        v => !!v || this.$t('Field required')
        ]
        this.emailRules = [
        v => !!v || this.$t('Field required'),
        v => /.+@.+/.test(v) || this.$t('E-mail must be valid')
        ]
        this.messageRules = [
        v => !!v || this.$t('Field required'),
        v => (v || '').length > 10 || this.$t('Message too short'),
        ]
    }
}
</script>
<style lang="scss" scoped>
.theo-photo {
    filter: saturate(0);
    float: right;
    border: 4px solid #121212;
}
.contact-page {
    padding: 2em;
    height: 100%;
    margin-bottom: 6rem;
}
</style>