<template>
  <v-container grid-list-xs class="d-flex justify-center align-center page-404 flex-column">
    <div class="d-flex justify-center align-center  flex-column">
      <v-img contain height="128" width="128" src="/icon.png"></v-img>
      <h1>{{ $t('Page Not Found') }}</h1>
      <v-btn text outlined to="/">Return home</v-btn>
    </div>
  </v-container>
</template>

<script>

  export default {
    name: 'PageNotFound',
    metaInfo() {
        return { title: this.$store.state.appName + ' - 404' }
    },  
    data: function() {
      return {}
    },
    components: {
    },
  }
</script>
<style lang="scss" scoped>
.page-404 {
  height: 100%;
}
</style>