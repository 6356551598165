
const ProjectList = [
{
"name": "Un site web pour un artiste peintre",
"employer": "niall",
"start_date": "01/03/2010",
"end_date": "01/09/2010",
"cover": "/projects/niall.png",
"domains": ["frontend"]
},
{
"name": "Une marketplace carto pour les commerçants de Grenoble",
"employer": "clicstreet",
"start_date": "01/01/2012",
"end_date": "01/01/2014",
"cover": "/renders/clicstreet_mockup.png",
"domains": ["cartography"]
},
{
"name": "Un portail de paiement sécurisé pour monnaie dématérialisée",
"employer": "alchemist_coop",
"start_date": "01/01/2015",
"end_date": "01/01/2018",
"cover": "/projects/alchemistpay.png",
"domains": ["frontend", "backend"]
},
{
"name": "La détection de comportements frauduleux",
"employer": "alchemist_coop",
"start_date": "01/01/2015",
"end_date": "01/01/2018",
"cover": "/projects/fraud.jpeg",
"domains": ["machine-learning", "business-intelligence"]
},
{
"name": "Un backoffice de gestion d'une boutique en ligne",
"employer": "alchemist_coop",
"start_date": "01/01/2015",
"end_date": "01/01/2018",
"cover": "/projects/phonealch.png",
"domains": ["frontend", "backend", "business-intelligence"]
},
{
"name": "Un dashboard de monitoring des métriques du site",
"employer": "alchemist_coop",
"start_date": "01/01/2015",
"end_date": "01/01/2018",
"cover": "/projects/phonedash.png",
"domains": ["frontend", "backend", "business-intelligence"]
},
{
"name": "Un site web pour un cabinet d'architectes",
"employer": "aha",
"start_date": "01/01/2017",
"end_date": "01/01/2018",
"cover": "/renders/AHA_mockup.jpg",
"domains": ["frontend", "backend"]
},
{
"name": "Une interface de paiement et d'abonnement pour une webapp",
"employer": "iprotego",
"start_date": "01/01/2018",
"end_date": "01/01/2020",
"cover": "/projects/stripe.jpg",
"domains": ["frontend", "backend"]
},
{
"name": "Des tokenizers pour le parsing de mots-clés dans des LLMs",
"employer": "iprotego",
"start_date": "01/01/2018",
"end_date": "01/01/2020",
"cover": "/projects/tokenizer.png",
"domains": ["machine-learning"]
},
{
"name": "Une API au service de plusieurs WebApps et applis Mobiles",
"cover": "/projects/api.avif",
"employer": "iprotego",
"start_date": "01/01/2018",
"end_date": "01/01/2020",
"domains": ["backend", "frontend"]
},
{
"name": "Un backoffice de déploiement de microservices",
"employer": "cogepart",
"start_date": "01/01/2021",
"end_date": "01/01/2022",
"cover": "/projects/microservices.jpeg",
"domains": ["frontend", "backend"]
},
{
"name": "Une application cartographique de gestion de flotte",
"employer": "cogepart",
"start_date": "01/01/2021",
"end_date": "01/01/2022",
"cover": "/renders/cogepart_mockup.jpg",
"domains": ["frontend", "machine-learning", "cartography", "business-intelligence"]
},
{
"name": "Un microservice ML d'optimisation de parcours pour la logistique",
"employer": "cogepart",
"start_date": "01/01/2021",
"end_date": "01/01/2022",
"cover": "/projects/optim.png",
"domains": ["backend", "machine-learning", "frontend"]
},
{
"name": "Des optimisations de parcours pour la livraison à domicile",
"employer": "cogepart",
"start_date": "01/01/2021",
"end_date": "01/01/2022",
"cover": "/projects/logistics.jpg",
"domains": ["machine-learning", "cartography", "frontend"]
},
{
"name": "Une page de monitoring de l'offre de Vélo électriques à Marseille",
"employer": "lezero",
"start_date": "01/01/2023",
"end_date": "01/01/2024",
"cover": "/renders/LeZero_mockup.jpg",
"domains": ["frontend", "backend", "cartography", "business-intelligence"]
},
{
"name": "Une interface pour jouer des partitions au piano",
"cover": "/projects/miid.png",
"start_date": "01/01/2022",
"end_date": "01/01/2023",
"employer": "th",
"domains": ["frontend", "backend"]
},
{
"name": "Une API interfaçant avec du MIDI pour contrôler un piano",
"employer": "th",
"start_date": "01/01/2022",
"end_date": "01/01/2023",
"cover": "/projects/midipiano.jpg",
"domains": ["backend", "frontend"]
},
{
"name": "Une application de monitoring de croissance végétale",
"employer": "th",
"start_date": "01/05/2024",
"end_date": "01/07/2024",
"cover": "/projects/yucca.jpeg",
"domains": ["machine-learning"]
},
{
"name": "Une webapp pour générer des parcours dans les Calanques",
"employer": "th",
"start_date": "01/09/2023",
"end_date": "01/03/2024",
"cover": "/projects/calanques.png",
"domains": ["cartography", "frontend", "backend"]
},
{
"name": "Une application MIDI générative de musique traditionnelle",
"cover": "/projects/genernotes.avif",
"start_date": "01/01/2024",
"end_date": "01/08/2024",
"employer": "th",
"domains": ["machine-learning", "frontend"]
},
{
"name": "Une représentation statistique des occurrences de notes dans un morceau",
"cover": "/projects/dipnotes.jpeg",
"start_date": "01/01/2024",
"end_date": "01/08/2024",
"employer": "th",
"domains": ["business-intelligence"]
}
]

export default ProjectList;