<template>
  <v-card
    md="3"
    height="100%"
    class="proj-card rounded-0"
    :class="extra ? 'extra': ''"
    elevation="0"
  >
    <v-img
      :src="project.cover"  
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
      width="100%"
    >
      <div class="pa-2 d-flex flex-column">
        <TheoDomainIcon
          class="mt-2"
          v-for="(domain, idx) in project.domains"
          :domain="domains[domain]"
          label="true"
          :key="idx"
        ></TheoDomainIcon>
      </div>
    </v-img>

    <v-card-title class="d-flex flex-column align-start">
        <v-img max-height=64 max-width=64 contain class="mr-6 project-icon" :src="'/clients/' + project.employer + '.png'" />
        <div class="project-title">
          {{ project.name }}
        </div>
    </v-card-title>
    <v-card-text v-if="extra">
      <p>
        Un projet mené pour le compte de 
        <v-img
          max-height=64
          max-width=64
          contain
          class="project-icon d-inline-block proj-cli"
          :src="'/clients/' + project.employer + '.png'"
        />
        de {{ project.start_date| moment("MMMM YYYY") }} à {{ project.end_date| moment("MMMM YYYY") }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script>

import TheoDomainIcon from '@/components/TheoDomainIcon.vue';
export default {
  name: 'TheoProjectCard',
  data() {
    return {
    }
  },
  props: {
    project: {},
    extra: { type: Boolean, default: false }
  },
  methods: {
  },
  components: {
    TheoDomainIcon
  },
  computed: {
    domains() {
      return this.$store.state.domains
    },
  },
}
</script>
<style lang="scss">
.proj-card {
  transition: transform 0.2s ease;
  // overflow: hidden;
  .project-title {
    width: 100%;
    overflow-x: hidden;
    display: inline-block;
    word-break: keep-all;
  }
  .proj-cli {
    border:1px solid #eee;
    padding:2px 10px;
    position: relative;
    top: 6px;
    border-radius: 4px;
  }
}
.proj-card:not(.extra) {
  .project-title {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
