import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import DomainView from '../views/DomainView.vue'
import ProjectsView from '@/views/ProjectsView.vue'
import ContactView from '@/views/ContactView.vue'
import PageNotFound from '@/views/PageNotFound.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/development',
    name: 'development',
    component: AboutView
  },
  {
    path: '/development/frontend',
    name: 'frontend',
    component: DomainView
  },
  {
    path: '/development/cartography',
    name: 'cartography',
    component: DomainView
  },
  {
    path: '/development/backend',
    name: 'backend',
    component: DomainView
  },
  {
    path: '/development/business-intelligence',
    name: 'business-intelligence',
    component: DomainView
  },
  {
    path: '/development/machine-learning',
    name: 'machine-learning',
    component: DomainView
  },
  {
    path: '/past-projects',
    name: 'past-projects',
    component: ProjectsView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/404',
    name: '404',
    component: PageNotFound
  },
  { path: "*",
    beforeEnter() {
      window.location = '/404'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 800)
    })
  }
})

export default router
