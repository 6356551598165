var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"theo-map"},[_c('l-map',{ref:"map",staticClass:"map",attrs:{"zoom":_vm.map.zoom,"options":_vm.map.options,"center":_vm.map.center}},[_c('l-tile-layer',{attrs:{"options":_vm.map.options,"url":_vm.map.url}}),_vm._l((_vm.activeMarkers),function(place,idx){return _c('l-marker',{key:''+_vm.storyIndex+'-'+idx,attrs:{"options":{ 'opacity': place.opacity},"lat-lng":place.pos}},[(place.text)?_c('l-popup',{ref:'popup',refInFor:true,attrs:{"options":{
        autoClose: false,
        keepInView: true,
        className: place.className ? place.className : '',
        offset: [0, 42],
        closeButton: false,
      }}},[_vm._v(_vm._s(place.text))]):_vm._e()],1)}),_c('l-polygon',{attrs:{"lat-lngs":_vm.irelandlatlng,"color":'green'}},[_vm._v(" >")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }