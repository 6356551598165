<template>
  <div class="domain-icon overline">
    <div class="square-icon" :style="colorMe(domain)">
      <span class="slug">{{ domain.slug }}</span>  
    </div>
    <span v-if="label" class="domain-label">
      {{ title }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'TheoLogo',
    props: ['domain', 'title', 'label'],
    methods: {
      colorMe(domain) {
        return {"background": domain.primColor}
      }
    }
  }
</script>

<style lang="scss">
.domain-icon {
  display: flex;
  align-items: center;
  justify-content: start;
  .square-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    width: 2em;
    height: 2em;
    color:white;
    font-weight: bold;
    .slug {
      text-transform: capitalize;
    }
  }
  .domain-label {
    padding-left: 1em;
  }

}
</style>