<template>
    <v-container>

        <h4 class="icoded pa-2 rounded-0">{{  lead }}</h4>
    <v-list class="projects rounded-0">
        <v-list-item
            v-for="(item,i) in slicedProjects"
            :key="i"
            @click="handleClick(item)"
        >
            <v-list-item-icon class="text-left">
              <v-img class="mr-6" max-height=32 max-width=64 contain  :src="'/clients/' + item.employer + '.png'" />
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
        </v-list-item>
        <v-btn dark rounded-0 block class="expand-proj"  @click="innerEnd=null" v-if="innerEnd"><v-icon>mdi-plus-circle</v-icon></v-btn>
    </v-list>
</v-container>
</template>
<script>
  export default {
    name: 'TheoClients',
    props: {
      lead: String,
      start: { type: Number, default: 0},
      end: { type: Number, default: 5},
      projects: { type: Array, default() {return []}}
    },
    data() {
      return {
        headers: [],
        innerEnd: this.projects.length > 5 ? this.end : null
      }
  },
  methods: {
    handleClick(item) {
      this.$emit('proj-clicked', item)
    }  
  },
  computed: {
    slicedProjects() {
      if (!this.innerEnd)
        return this.projects.slice(this.start);
      else
        return this.projects.slice(this.start, this.end);
    }
  }
}
  </script>
  <style lang="scss" scoped>
    .projects .v-item--active .v-expansion-panel-header{
      // transition: all 0.5s ease;
      color: var(--domain-color);
      font-size: 1em;
      font-weight: bold;
    }
    .expand-proj {
      cursor: pointer;
      &.v-btn {
        border-radius: 0;
        background: var(--domain-color)
      }
    }
    .icoded {
      color: white;
      background: var(--domain-color);
    }
  </style>
  