<template>
  <v-container class="responsive">
    <v-row>
      <v-col cols="12" class="d-flex d-md-none align-start justify-start">
        <v-row>
          <v-col cols="4" md="12" v-for="(dev,idx) in devices" :key="idx" class="d-flex pa-0 justify-center align-start pt-0 mt-0">
              <v-radio-group dense hide-details v-model="activeDevice" v-if="activeDevice" class="pt-0 mt-0">
              <v-radio :value="dev" class="pt-0 mt-0">
                <template v-slot:label>
                  <div>
                    <v-icon>{{ dev.icon }}</v-icon>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            </v-col>
          </v-row>
      </v-col>
      <v-col cols="12" md="8" class="d-flex align-center justify-center">
        <div class="chip-container"  :class="activeDevice.value">
          <div class="wrapper">
            <div class="box header">Header</div>
            <div class="box sidebar">Menu</div>
            <div class="box sidebar2">Sidebar 2</div>
            <div class="box content">Content
              <br /> More content than we had before so this column is now quite tall.</div>
            <div class="box footer">Footer</div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="d-none d-md-flex align-center ">
        <v-radio-group v-model="activeDevice" v-if="activeDevice">
          <template v-slot:label>
            <span class="d-none d-md-inline">
              {{  $t('Content adapts to the device') }}
            </span>
          </template>
          <v-row>
            <v-col cols="4" md="12" v-for="(dev,idx) in devices" :key="idx" >
              <v-radio :value="dev">
                <template v-slot:label>
                  <div>
                    <v-icon>{{ dev.icon }}</v-icon> {{ dev.name }}
                  </div>
                </template>
              </v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'TheoResponsive',
    methods: {
      deleteMe(idx) {
        this.colors.splice(idx, 1)
      }
    },
    data() {
      return {
        devices: [
          {icon: "mdi-cellphone", name: this.$t('device.cellphone'), value: ''},
          {icon: "mdi-tablet", name: this.$t('device.tablet'), value: 'mid'},
          {icon: "mdi-monitor", name: this.$t('device.monitor'), value: 'lrg'}
        ],
        activeDevice: null
      }
  },
  mounted() {
    this.activeDevice = this.devices[0]
  }
  }
</script>

  <style lang="scss" scoped>
.responsive {

  .sidebar {
      grid-area: sidebar;
    }

    .sidebar2 {
      grid-area: sidebar2;
    }

    .content {
      grid-area: content;
    }

    .header {
      grid-area: header;
    }

    .footer {
      grid-area: footer;
    }

    .wrapper {
      background-color: #fff;
      border-radius: 4px;;
      color: #444;
    }


  .box {
    background-color: map-get($cyan, base);
    color: #fff;
    border-radius: 5px;
    padding: 10px;
  }

  .header,
  .footer {
    background-color: map-get($indigo, base);
  }
  .sidebar {
    background-color: map-get($orange, base);
    color: #fff;
  }

  .sidebar2 {
    background-color: map-get($green, base);
    color: #fff;
  }
}


.chip-container {
  font-size: 10px;
  transition: all 1s ease-in-out;
  border-radius: 8px;
  width: 12rem;
  height: 16rem;
  overflow: scroll;
  border: 16px solid #f2f2f2 !important;
  background: #f2f2f2;

  .wrapper {
      padding: 16px;
      display: grid;
      grid-gap: 0.5em;
      grid-template-areas:
      "header"
      "sidebar"
      "content"
      "sidebar2"
      "footer"
    }
  &.mid {
    width: 16rem;
    height: 12rem;
    .wrapper {
      grid-template-columns: 20% auto;
      grid-template-areas:
      "header   header"
      "sidebar  content"
      "sidebar2 sidebar2"
      "footer   footer";
    }
  }
  &.lrg {
    width: 20rem;
    height: 16rem;
    .wrapper {
      grid-gap: 10px;
      grid-template-columns: 60px auto 60px;
      grid-template-areas:
      "header  header  header"
      "sidebar content sidebar2"
      "footer  footer  footer";
      max-width: 600px;
    }
  }
}


.chip-container {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 16px;
    width: 10rem;
    height: 15rem;
    &.mid {
      width: 24rem;
      height: 16rem;
    }
    &.lrg {
      width: 31rem;
      height: 21rem;
      .wrapper {
        grid-gap: 20px;
        grid-template-columns: 120px auto 120px;
      }
    }
      
  }

}
.responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 400px;
}
</style>