<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }" >
        <v-btn
          color="primary"
          block
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-earth</v-icon>{{ $t('Language') }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          primary
           @click="setLocale(item.value)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
  export default {
    name: 'TheoLangPicker',
    data: () => ({
      items: [
        { title: 'Français', value: 'fr' },
        { title: 'English', value: 'en' },
      ],
    }),
    props: [],
    methods: {
      setLocale(code){
        this.$i18n.locale = code;
      }
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>
.lang-picker {

}
</style>