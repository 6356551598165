<template>
  <v-app id="app" ref="app">
    <!-- {{ $route.fullPath }} -->
    <v-img 
      v-if="!drawer && $route.name !='home'"
      @click="drawer=true"
      height=48
      width=48
      src="/icon.png"
      class="rounded-lg menu-toggle"
      :class="$vuetify.breakpoint.mdAndUp ? 'd-none' : 'd-fixed'"
    ></v-img>
    <!-- <v-app-bar
      app
      flat
      top
      color="transparent"
      class="white--text"
      dark
    >
    <div></div>
    <v-spacer></v-spacer>
    <span @click="toggleDrawer">  
      <TheoLogo ></TheoLogo>
    </span>

    </v-app-bar> -->
    <v-main class="main" id="main">
      <transition
      :name="transitionName"
      :mode="transitionMode"
      :enter-active-class="transitionEnterActiveClass"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
      
      >
      <router-view :key="$route.fullPath" v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </transition>
    </v-main>
    <TheoNav></TheoNav>
  </v-app>
</template>

<script>
import TheoLogo from './layout/TheoLogo.vue';
import TheoNav from './layout/TheoNav.vue';

const DEFAULT_TRANSITION = 'slide';
const DEFAULT_TRANSITION_MODE = 'out-in';

export default {

  name: 'App',
  components: {
    TheoLogo,
    TheoNav
  },

  data: () => ({
      logoShow: true,
      group: null,
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: '',
    //
  }),created() {
    this.logoShow = (this.$route.name != 'home')
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION;
      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      this.transitionMode = DEFAULT_TRANSITION_MODE;
      this.transitionEnterActiveClass = `${transitionName}-enter-active`;

      if (to.meta.transitionName === 'zoom') {
        this.transitionMode = 'in-out';
        this.transitionEnterActiveClass = 'zoom-enter-active';
        document.body.style.overflow = 'hidden';
      }

      if (from.meta.transitionName === 'zoom') {
        this.transitionMode = null;
        this.transitionEnterActiveClass = null;
        document.body.style.overflow = null;
      }

      this.transitionName = transitionName;

      next();
    });
  },
  computed: {
    drawer:{
      get() { return this.$store.state.drawer; },
      set(val) { this.$store.commit('setDrawer', val); }
    }
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    toggleDrawer() {
      this.$store.commit('setDrawer', !this.drawer);
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter() {
      // element.style.height = 'auto';
    },
  },
  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>

<style lang="scss">
#app {
  height: 100%;
  // background: var(--v-primary-base);
}
.main {
  height: 100%;
  overflow-x: hidden;
}
.d-fixed {
  position: fixed;
}
.bread {
  border: 1px solid red;
}
.menu-toggle {
  z-index: 5;
  bottom: 2rem;
  right: 2rem;
  // border: 2px solid var(--v-primary-base);

  animation: glow 4s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 20px 0px rgba(21, 120, 211, 1);
  }
  25% {
    box-shadow: 0 0 10px 10px rgba(21, 120, 211, 0.55);
  }
  50% {
    box-shadow: 10px 10px 10px 10px rgba(21, 120, 211, 0.25);
  }
  75% {
    box-shadow: -10px 0 40px 0px rgba(21, 120, 211, 0.55);
  }
  to {
    box-shadow: 0 0 10px 10px rgba(21, 120, 211, 0.25);
  }
}



.mini-logo {
  position: relative;
  top: -2px;
  font-family: monospace;
  font-weight: bold;
  color: var(--v-primary-base);
}
.domain-group {
  margin: 0;
  .front {
    border-left: 4px solid green;
  }
}





.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 1s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>