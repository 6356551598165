const SocialList = [
    {
        name: 'Malt',
        active: true,
        code: 'malt',
        url: 'https://www.malt.fr/profile/theohennessy',
        handle: 'theohennessy',
        text: '',
        description: '',
        color: '#FC5656',
        icon: '/socials/malt.png'
    },
    {
        name: 'GitHub',
        active: true,
        code: 'github',
        url: 'https://github.com/theohennessy',
        handle: 'theohennessy',
        text: '',
        description: '',
        color: '#14191E',
        icon: '/socials/github.png'
    },
    {
        name: 'LinkedIn',
        active: true,
        code: 'linkedin',
        url: 'https://www.linkedin.com/in/theohennessy/',
        handle: 'theohennessy',
        text: '',
        description: '',
        color: '#007FBC',
        icon: '/socials/linkedin.png'
    },
    {
        name: 'Telegram',
        active: true,
        code: 'telegram',
        url: 'https://t.me/theohennessy',
        handle: 'theohennessy',
        text: '',
        description: '',
        color: '#24ABEC',
        icon: '/socials/telegram.png'
    },
    {
        name: 'Email',
        active: true,
        code: 'email',
        url: 'mailto:theohennessy@gmail.com',
        handle: 'theohennessy@gmail.com',
        text: '',
        description: '',
        color: '#319F24',
        icon: '/socials/email.png'
    },
    {
        name: 'Instagram',
        active: false,
        code: 'instagram',
        url: '',
        handle: '',
        text: '',
        description: '',
        color: '',
        icon: ''
    },
    {
        name: 'Medium',
        active: false,
        code: 'medium',
        url: '',
        handle: '',
        text: '',
        description: '',
        color: '',
        icon: ''
    }
]

export default SocialList