<template>
    <v-container class="responsive d-flex  justify-center">
      <v-card flat class="rounded-lg chip-container pb-0 d-flex flex-wrap justify-space-around align-center" color="white">
        <v-card 
            v-show="clickCount>0" 
            flat dark class="count-widget rounded-lg chip my-4 mx-2" 
            :class="{'click-active': hasClick}">
            <h5>clicks</h5>
            <br/>
            {{  clickCount  }}
        </v-card>
        <v-card 
            flat dark v-show="clickCount>5" class="rounded-lg chip my-4 mx-2  cux-2" :color="colors[1]">

          <Bar
            id="my-chart-id"
            :options="chartOptions"
            :data="chartData"
            class="mb-8 graph rounded-0"
          />
        </v-card>
        <v-card 
            flat dark v-show="clickCount>15" class="rounded-lg chip my-4 mx-2 cuy-2 text-center" :color="colors[5]">
            <small>CPM:<br/><strong>{{ cpm }}</strong><br/>
            Max:<br/><strong>{{ max }}</strong><br/>
            </small>
</v-card>
        <v-card 
            flat dark class="rounded-lg chip my-4 mx-2 cuy-2 cux-2" :color="colors[2]">
            <v-btn 
                block
                tile
                ref="clicker"
                outlined
                x-large
                class="clicker px-0 py-0" color="white"
                @click="incr"
            >
                click
            </v-btn>
            <v-switch
              x-small
              v-if="clickCount>24"
              v-model="autoClick"
              :label="`AUTO`"
            ></v-switch>
        </v-card>
        <v-card v-show="clickCount>10000" 
            flat dark class="rounded-lg chip my-4 mx-2" :color="colors[3]">
        </v-card>
        <!-- <v-card 
            flat dark class="rounded-lg chip my-4 mx-2" :color="colors[4]">
        </v-card> -->
      </v-card>
    </v-container>
  </template>
  
  <script>
    import { Bar } from 'vue-chartjs'
    import { Chart as ChartJS, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
    
    ChartJS.register(Tooltip, Legend, BarElement, CategoryScale, LinearScale)
    

    export default {
      name: 'TheoResponsive',
      data() {
        return {
            labels: [''],
            data: [0],
            chartOptions: {
              animation:false,
              responsive: true,
              plugins: {legend: {
                display: false,
              }},
              scales: {
                x: { display: false},
                y: { display: false, max: 20}
              }
            },
            autoClick: false,
            clicker: null,
            clickLoop: null,
            updateLoop: null,
            clickCount: 0,
            CPMCount: 0,
            hasClick: false,
            colors: ['blue', 'orange', 'green','light-green', 'orange', 'deep-orange']
        }
      },
      computed: {
        chartData() {
          return {
              labels: this.labels.slice(-25),
              datasets: [ { data: this.data.slice(-25), backgroundColor: 'rgba(255,255,255,0.8)' } ]
          }
        },
        cpm() {
          let sum =this.data.reduce(function(a,b){ return a + b})
          return Math.floor(200*sum/this.data.length) / 100
        },
        max() {
          let sorted = this.data.slice().sort((a,b) =>  a < b )
          return sorted[0];
        }
      },
      components: { Bar },
      mounted() {
        this.clicker = this.$refs['clicker']
        this.updateLoop = setInterval(this.logCPM, 500);
      },
      methods: {
        fakeClick() {
          if (Math.random() > 0.55)
            this.clicker.click({});
        },
        incr() {
            this.hasClick = true;
            this.clickCount++;
            this.CPMCount++;
            var vm = this
            setTimeout(function(){vm.hasClick = false;},100);
        },
        logCPM() {
          this.data.push(this.CPMCount);
          this.labels.push('')
          this.CPMCount = 0;
        }
      },
      watch: {
        autoClick(val) {
          if (val)
            this.clickLoop = setInterval(this.fakeClick, 100)
          else
            clearInterval(this.clickLoop);
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  .chip {
    height: 4rem;
    font-size: 16px;
    width: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.cux-2 {
        width: 9rem;
    }
    &.cuy-2 {
        height: 9rem;
    }
  }
  .clicker {
    position: relative;
    border-width: 0;
    padding: 0 !important;
    min-width: 0 !important;
    width: 100% !important;
    height: 3rem !important;
  }
  .graph {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .chip-container {
    display: flex;
    border: 16px solid #f2f2f2 !important;
    font-size: 64px;
    height: 5em;
    width: 5em;
  }
  .responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 400px;
  }
  .count-widget {
    transition: transform 0.2s ease;
    background: var(--v-primary-base);

  }
  .click-active {
    transform: scale(1.2);
}
  </style>