import { render, staticRenderFns } from "./TheoSocials.vue?vue&type=template&id=7ae6189c&scoped=true"
import script from "./TheoSocials.vue?vue&type=script&lang=js"
export * from "./TheoSocials.vue?vue&type=script&lang=js"
import style0 from "./TheoSocials.vue?vue&type=style&index=0&id=7ae6189c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ae6189c",
  null
  
)

export default component.exports